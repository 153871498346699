import React, { useState } from 'react';
import { navigate } from 'gatsby';
import addToMailchimp from 'gatsby-plugin-mailchimp';

import Layout from '../../components/Layout';

import imgBlockR from '../../assets/images/webinar/Photo 1 (2).png';
import iconTick from '../../assets/images/svg/tick.svg';

import imgDots from '../../assets/images/home-page/Dots.png';

import mondayConfig from '../../constants/monday-config';

import '../../assets/styles/pages/webinar-page-new.scss';

const EMPTY_FORM_DATA = {
  firstName: '',
  lastName: '',
  userEmail: '',
};

const Webinar = () => {
  const [formData, setFormData] = useState(EMPTY_FORM_DATA);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const mondayData = {
      BOARD_ID: mondayConfig.boardLeadManagementId,
      GROUP_ID: mondayConfig.groupId,
      itemName: `${formData.firstName} ${formData.lastName}`,
      values: {
        status_3: { label: 'Webinar' }, // Source
        status_5: mondayConfig.status,
        first_name: formData.firstName,
        last_name: formData.lastName,
        email9: formData.userEmail,
        date5: mondayConfig.date,
        status_1: window?.userFromGoogleAds ? 'Yes' : 'No', // Google Ads
        status_101: window?.userFromFacebookAds ? 'Yes' : 'No', // Facebook Ads
      },
    };

    await fetch('/api/monday', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(mondayData),
    });

    const MailchimpData = {
      FNAME: formData.firstName,
      LNAME: formData.lastName,
      MMERGE4: new Date().toISOString().split('T')[0], // contact date
      MMERGE17: 'Webinar', // Source
    };
    addToMailchimp(formData.userEmail, MailchimpData);

    setFormData(EMPTY_FORM_DATA);
    navigate('/webinar/thank-you');
  };
  return (
    <Layout
      title="Webinar: Teaching English in Spain after Brexit | TEFL Iberia"
      description="Join TEFL Iberia for a discussion on how Brexit has impacted the TEFL industry and how to
get set up as an English teacher in Spain."
    >
      <div className="webinar-page-new">
        <section className="second-section">
          <div className="container">
            <div className="row top">
              <div className="col-12 col-lg-6 col-md-5">
                <div className="block_r">
                  <div className="block_r__img">
                    <img src={imgBlockR} alt="" className="block_r__img" />
                  </div>

                  <div className="block__logo block_r__trinity">
                    <img src={imgDots} alt="iconTrinity" />
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-6 col-md-7">
                <div className="block_l">
                  <h1 className="c-title-42">Webinar: Teaching English in Spain after Brexit</h1>
                  <p className="c-text-18">
                    Are you thinking about teaching English in Spain but concerned about Brexit?
                    Richard, Jayde and Ian discuss how to navigate post-Brexit bureaucracy and get
                    set up as an English teacher in Spain. We will cover:
                  </p>
                  <div className="c-benefit-list block_l__benefits">
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        The impact of Brexit on the TEFL industry in Spain
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">How to get started teaching English abroad</p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">How to get a student visa for Spain</p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        How to work legally in Spain for 12 months or more
                      </p>
                    </div>
                  </div>
                  <div className="form-hat">
                    <h4 className="form-hat__title">Thursday 18th February 2022, 5pm GMT</h4>
                  </div>
                  <form onSubmit={handleSubmit} className="form">
                    <h1 className="c-title-22 form__title">Register for free webinar</h1>

                    <label className="form__first-name">
                      <p className="c-text-14 text-under">First Name</p>
                      <input
                        className="input__first-name"
                        type="text"
                        placeholder="John"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleInputChange}
                        required
                      />
                    </label>
                    <label className="form__last-name">
                      <p className="c-text-14 text-under">Last Name</p>
                      <input
                        className="input__last-name"
                        type="text"
                        placeholder="Smith"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleInputChange}
                        required
                      />
                    </label>

                    <label className="form__first-name email">
                      <p className="c-text-14 text-under">Email</p>
                      <input
                        className="input__email"
                        type="email"
                        placeholder="johnsmith@gmail.com"
                        name="userEmail"
                        value={formData.userEmail}
                        onChange={handleInputChange}
                        required
                      />
                    </label>
                    <button type="submit" className="form__btn c-btn c-btn--red">
                      Register
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Webinar;
